import { configureStore } from "@reduxjs/toolkit";
import { api } from "api";
import { rtkQueryErrorLogger } from "features/errorLogger";
import errorReducer from "features/error";
import storesSlice from "features";

const middlewares = [api.middleware, rtkQueryErrorLogger];

// if (__DEV__) {
//   const createDebugger = require("redux-flipper").default;
//   middlewares.push(createDebugger());
// }

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    error: errorReducer.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    /** @TODO refactor it only to uploadImage action */
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
});

export default store;
