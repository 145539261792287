import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { surveyData } from "config/survey";
import { StyledOption, StyledOptionsWrapper, StyledPaper } from "./styles";

const Survey = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = surveyData.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ width: "100%", flexGrow: 1 }}>
      <StyledPaper elevation={0} sx={{}}>
        <Typography variant="h3" sx={{ fontSize: "28px", fontWeight: "700" }}>
          {surveyData[activeStep].title}
        </Typography>
        <StyledOptionsWrapper>
          {surveyData[activeStep].options.map((item) => {
            return (
              <StyledOption>
                <Typography className="title" sx={{ fontFamily: 'Poppins, sans-serif' }}>{item.title}</Typography>
                <Typography className="description" sx={{ fontFamily: 'Poppins, sans-serif' }}>
                  {item.description}
                </Typography>
              </StyledOption>
            );
          })}
        </StyledOptionsWrapper>
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
        />
      </StyledPaper>
    </Box>
  );
};

export default Survey;
