import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
export const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const location = useLocation();
  const dataFromStorage = localStorage.getItem("formData");

  const [formData, setFormData] = useState(
    dataFromStorage ? JSON.parse(dataFromStorage) : {}
  );

  const updateFormData = (data) => {
    const newData = { ...formData, ...data };
    setFormData(newData);
    localStorage.setItem("formData", JSON.stringify(newData));
  };

  useEffect(() => {
    const dataFromStorage = localStorage.getItem("formData");
    if (dataFromStorage) {
      setFormData(JSON.parse(dataFromStorage));
    } else {
      setFormData({});
    }
  }, [location]);

  return (
    <FormContext.Provider
      value={{
        formData,
        updateFormData,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = () => {
  return useContext(FormContext);
};
