import {createTheme, ThemeProvider} from "@mui/material/styles";
import ordersLogo from "assets/img/ordersLogo.png";
import {Box, Grid} from "@mui/material";
import {Header} from "../pages/Header/Header";
import {StyledFooterBox, StyledNextButton} from "./styles";
import TermsAndUsePrivacyPolicy from "./TermsPrivacy";
import React from "react";

const isKiosk = () => {
  // Your logic to detect kiosk mode
  //return window.navigator.userAgent.includes("Kiosk"); // just an example
  return false;
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#ff6b00",
    },
  },
  header: {
    logo: ordersLogo,
    logoalt: "Orders.co Logo",
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          ...(isKiosk() && {
            fontSize: "2rem", // or whatever size you want
            padding: "16px 32px", // adjust padding as needed
          }),
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ...(isKiosk() && {
            fontSize: "2rem", // adjust as per your need
          }),
        },
        input: {
          ...(isKiosk() && {
            padding: "10px", // adjust padding as needed
          }),
        },
      },
    },
  },
});
export const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box className="App">
        <Header />
        {children}
      </Box>
    </ThemeProvider>
  )
}