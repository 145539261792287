import * as React from 'react';
import Slider from '@mui/material/Slider';
import CheckIcon from '@mui/icons-material/Check';
import {useMatchMobile} from "../hooks/useMatchMobile";
import {useEffect, useRef} from "react";
import * as events from "events";

const CustomThumb = (props) => {
  // Extracting the component prop to spread the remaining props to the span
  const { component: Component, ...otherProps } = props;
  const { isMatchMobile } = useMatchMobile();
  return (
    <span
      {...otherProps}
      style={{
        color: '#9DFFFF',
        padding: '10px',
        borderRadius: '50%',
        backgroundColor: '#9DFFFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '20px',
        height: '20px',
        position: 'absolute',
        right: '-15px',
        top: isMatchMobile ? '10px' : '4px',
      }}
    >
      <CheckIcon style={{ color: 'black', fontSize: '16px' }} />
    </span>
  );
};

export default function CustomSlider() {
  const element = useRef(null);

  useEffect(() => {
    const el = document.getElementById('slider');
    el.focus();
  },[element])

  return (
    <Slider
      ref={element}
      defaultValue={100}
      aria-label="Temperature"
      id={'slider'}
      step={100}
      min={10}
      max={100}
      onChnage={() => {}}
      color="secondary"
      sx={{
        pointerEvents: 'none',
        height: '20px',
        position: 'relative',
        '& .MuiSlider-track': {
          color: '#2FDADA',
        },
        '& .MuiSlider-rail': {
          color: '#2FDADA',
        },
      }}
      components={{ Thumb: CustomThumb }}
    />
  );
}