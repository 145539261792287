import {
  MainGridContainer,
  MainGridContainerItemLeft,
  MainGridContainerItemLeftBox,
  MainGridContainerItemRight, MainGridContainerItemRightBox,
  StyledTitle
} from "./styles";
import {Box} from "@mui/material";
import Stepper from "../Stepper";
import React from "react";
import {useMatchMobile} from "hooks/useMatchMobile";
import {useLocation} from "react-router-dom";


export const MiddleContent  = () => {
  const { isMatchMobile } = useMatchMobile();
  const location = useLocation();
  const checkRoute = location.pathname === '/businessInfo' && isMatchMobile;
  return (
    <MainGridContainer container>
      {!isMatchMobile && (
        <MainGridContainerItemLeft item md={12} lg={6}>
          <MainGridContainerItemLeftBox>
            <StyledTitle>Build Your Restaurant Website In Less Than 60 Seconds!</StyledTitle>
          </MainGridContainerItemLeftBox>
        </MainGridContainerItemLeft>
      )}
      <MainGridContainerItemRight item lg={6} md={12} xs={12} sm={12}>
        <MainGridContainerItemRightBox>
          <Stepper />
        </MainGridContainerItemRightBox>
      </MainGridContainerItemRight>
    </MainGridContainer>
  )
}