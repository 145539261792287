/**
 *  
 * @param eventName String  
 * @param eventData Object  
 */
const sendToGTM = (eventName, eventData) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': eventName,
        ...eventData
    });
}

export default sendToGTM;