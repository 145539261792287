export const SignUpPathEnum = {
  NAME_INFO: "/",
  BUSINESS_INFO: "/businessInfo",
  EMAIL_INFO: "/emailInfo",
  EMAIL_VERIFY: "/emailVerify",
  ADDRESS_INFO: "/addressInfo",
  SIGN_UP_SUCCESS: "/success",
};

export const newSignUpPathEnum = {
  GENERATED_WEBSITE: '/website'
}
