import {Box, styled} from "@mui/material";

export const MainContentWrapperHeader = styled(Box)(({ theme }) => ({
  overflow:'auto',
}));
export const HeaderLogoWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '120px',
  'img': {
    cursor: 'pointer',
  },
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 auto',
  }
}));