import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

function TypingEffect({ text, speed = 100 }) {
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    // Start typing effect only if there's more text left to display
    if (index < text.length) {
      const timer = setInterval(() => {
        setDisplayedText((prevText) => prevText + text.charAt(index));
        setIndex((prevIndex) => prevIndex + 1);
      }, speed);

      // Clear interval when component is unmounted or when the entire text has been displayed
      return () => clearInterval(timer);
    }
  }, [index, text, speed]);

  return displayedText;
}

export default TypingEffect;
