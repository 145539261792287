import React from 'react';
import { get } from 'lodash';
import { TextField } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
const CustomTextInput = ({
   name,
   id,
   sx ,
   children,
   label = '',
   validate,
   inputProps,
   hidden,
   onChange,
   disabled,
   multiline,
   rows,
   select,
   helperText,
   InputProps,
   labelProps = {},
   onKeyPress,
   onBlur,
   ...params
 }) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;

  let parsedError = get(errors, name);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <TextField
            {...field}
            size="middle"
            hidden={hidden}
            error={!!parsedError || params.error || false}
            label={label}
            inputRef={field.ref}
            disabled={disabled}
            InputProps={InputProps}
            sx={{
              ...sx,
              '& label': { fontFamily: 'Poppins' },
              '& input': { fontFamily: 'Poppins' }
            }}
            multiline={multiline}
            rows={rows}
            select={select}
            inputProps={inputProps}
            onChange={onChange || field.onChange}
            onBlur={onBlur || field.onBlur}
            onKeyPress={onKeyPress}
            InputLabelProps={labelProps}
            value={field.value}
            fullWidth={true}
          >
            {children}
          </TextField>
        );
      }}
      {...params}
    />
  );
};

export default CustomTextInput;
