import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {StyledRadioBox} from "../styles";
import {Box} from "@mui/material";

const CustomFormRadio = (props) => {
  const { control } = useFormContext();
  const { name, label, options, defaultValue, sx, row, customStyles = { fontFamily: 'Poppins' } } = props;
  return (
    <FormControl
      component="fieldset"
      sx={{
        fontFamily: 'Poppins',
       '& .MuiInputLabel-root.Mui-focused': { color: '#000000' },
      }}
    >
      <StyledRadioBox
        component="legend"
        sx={{
          textAlign: 'end',
          color: '#000000',
          fontSize: '15px',
          fontFamily: 'Poppins',
          '& .MuiInputLabel-root.Mui-focused': { color: '#000000' },
        }}
      >{label}</StyledRadioBox>
      <Controller
        rules={{ required: true }}
        control={control}
        name={name}
        label={label}
        render={({ field }) => {
          return (
            <Box sx={{ textAlign: 'center', margin: '0 auto' }}>
              <RadioGroup
                name="radio-buttons-group"
                sx={{ ...customStyles, m: 0, display: 'flex', gap: '80px', fontFamily: 'Poppins', }}
                row={row}
                {...field}
                >
                {options.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    sx={item.value === 'self' ? { width: '100%' } : { ...customStyles }}
                    value={item.value}
                    control={<Radio />}
                    label={item.label}
                  />
                ))}
              </RadioGroup>
            </Box>
          );
        }}
      />
    </FormControl>
  );
};

export default CustomFormRadio;
