import React, {useEffect} from "react";
import "./App.css";
import { Layout } from "components/Layout";
import {useLocation} from "react-router-dom";
import {MiddleContent} from "pages/MiddleContent/MiddleContent";


function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Layout>
      <MiddleContent />
    </Layout>
  );
}

export default App;
