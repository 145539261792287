import { styled, Slider, Typography, Box, Button } from "@mui/material";

export const StepperSlider = styled(Slider)(({ theme }) => ({
  ".MuiSlider-rail": {
    background: theme.palette.primary.main,
  },
  color: theme.palette.primary.main,
  height: 3,
  paddingTop: theme.spacing(2, 0),
  "& .MuiSlider-thumb": {
    height: 22,
    width: 22,
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
  },
  "& .MuiSlider-track": {
    height: 3,
  },
}));

export const StyledEmail = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

export const MainBoxWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "87vh",
  background: '#E5E5E5',
  [theme.breakpoints.down('md')]: {
    minHeight: "100vh",
  }
}));


export const MainBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(8),
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4),
  }
}));


export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: "38px",
  fontWeight: 500,
  fontFamily: 'Poppins',
  color: '#000000',
    // [theme.breakpoints.down('sm')]: {
    //   fontSize: "40px",
    //   lineHeight: '45px',
    // }
}));

export const StyledVerifyTitle = styled(Typography)(({ theme }) => ({
  fontSize: "30px",
  fontWeight: 500,
  fontFamily: 'Poppins, sans-serif',
  lineHeight: '45px',
  color: '#000000',
  [theme.breakpoints.down('md')]: {
    fontSize: "30px",
    lineHeight: '45px',
  }
}));

export const StyledVerifyDescription = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 400,
  fontFamily: 'Poppins, sans-serif',
  lineHeight: '24px',
  color: '#000000',
}));

export const StyledEmailBox = styled(Box)(({ theme }) => ({
  // height: "275px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));


export const StyledBusinessTitle = styled(Typography)(({ theme }) => ({
  fontSize: "35px",
  fontWeight: 500,
  fontFamily: 'Poppins',
  lineHeight: '52.5px',
  color: '#000000',
  [theme.breakpoints.down('md')]: {
    fontSize: "30px",
    lineHeight: '45px',
  }
}));

export const StyledTitleAI = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "#333333",
  // height: "20px",
  // lineHeight: '1px',
  marginBottom: 0,
  fontFamily: 'Poppins',
  fontWeight: 500,
  [theme.breakpoints.down('md')]: {
    fontSize: "14px",
    lineHeight: '21px',
  }
}));

export const StyledSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontFamily: 'Poppins',
  marginBottom: 0,
  fontWeight: 400,
}));

export const StyledFooterBox = styled(Box)(({ theme }) => ({
  height: "98px",
  width: "100%",
  position: "fixed",
  bottom: 0,
  zIndex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "white",
  left: 0,

  // MuiButton-contained
  // MuiButton-contained
  "& .MuiButtonBase-root": {
    borderRadius: "24px",
    minWidth: "118px",
    marginRight: "30px",
    height: "48px",
    margin: "0 15px",
  },
  "& .MuiButton-contained": {
    borderRadius: "24px",
    minWidth: "118px",
    height: "48px",
    marginRight: "30px",
    color: "white",
    margin: "0 15px",
  },
}));

export const CustomAnimatedText = styled(Typography)(({ theme }) => ({
  transition: "color 0.3s ease",
  fontSize: "50px",
  lineHeight: '58.59px',
  color: '#00000',
  fontFamily: 'Poppins, sans-serif',
}));

export const StyledViewWebsiteButton = styled(Button)(({ theme }) => ({
  width: "280px",
  height: "53px !important",
  fontSize: "15px",
  borderRadius: '6px!important',
  textTransform: "none",
  fontWeight: 700,
  boxShadow: "none",
  fontFamily: 'Poppins',
  color: 'white',
  background: 'linear-gradient(to right, rgba(255, 107, 0, 1) 0%, rgba(173, 42, 0, 1) 100%)',
  '&:disabled': {
    background: 'rgba(224, 224, 224, 1)',
  }
}));

export const CutomImageWraper = styled(Box)(({ theme }) => ({
  width: "105px",
  margin: "0 auto",
  "& img": {
    width: "100%",
  },
}));

export const StyledLogoWrapper = styled(Button)(({ theme }) => ({
  "& img": {
    width: "100%",
  },
}));

export const StyledAddressWrapper = styled(Box)(({ theme, isSelected }) => ({
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  gap: 1,
  background: isSelected ? theme.palette.primary.main : "white",
  color: isSelected ? "white" : "black",
  borderRadius: "6px",
  cursor: "pointer",
  transition: "background-color 0.4s ease",
  "&: hover": {
    boxShadow: `0px 0px 3px 1px ${theme.palette.primary.main}`,
    // color: "black",
  },
  [theme.breakpoints.down("lg")]: {
    width: '98%'
  },
}));

export const StyledNextButton = styled(Button)(({ theme }) => ({
  width: "140px",
  height: "53px !important",
  fontSize: "15px",
  borderRadius: '6px!important',
  textTransform: "none",
  fontWeight: 700,
  boxShadow: "none",
  fontFamily: 'Poppins, sans-serif',
  color: 'white',
  background: 'linear-gradient(to right, rgba(255, 107, 0, 1) 0%, rgba(173, 42, 0, 1) 100%)',
  '&:disabled': {
    background: 'rgba(224, 224, 224, 1)',
  }
}));

export const StyledBackButton = styled(Button)(({ theme }) => ({
  width: "140px",
  height: "53px !important",
  fontSize: "15px",
  borderRadius: '6px!important',
  textTransform: "none",
  fontWeight: 700,
  boxShadow: "none",
  fontFamily: 'Poppins, sans-serif',
  color: '#FF8A00',
  '&:disabled': {
    background: 'rgba(224, 224, 224, 1)',
  }
}));

export const StyledRadioBox = styled(Box)(({ theme }) => ({
  textAlign: 'end',
  color: '#000000',
  fontSize: '15px',
  fontFamily: 'Poppins, sans-serif',
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#000000'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  }
}));


export const WrapButton = styled(Button)(({ theme }) =>({
  border: "1px solid #FFFFFF",
  color: '#FFFFFF',
  background: "none",
  borderRadius: '6px',
  textTransform: 'uppercase',
  "&:hover": {
    background: "none",
  },
}));

export const WrapBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: "24px 48px 0",
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: "24px 0px 24px",
  }
}));

export const WrapTypography = styled(Typography) (({ theme }) => ({
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '27px',
  color: '#FFFFFF',
  fontFamily: 'Poppins',
  padding: '4px 0',
}));

export const SecondHeader = styled(Box)({
  background: '#EFEFEF',
  height: 'auto',
});

export const SecondHeaderContainer = styled(Box)({
  width: '100%',
  maxWidth: '361px',
  textAlign: 'center',
  padding: '24px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0 auto',
});

export const SecondHeaderTitle = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: '30px',
  lineHeight: '45px',
  alignItems: 'center',
  textAlign: 'center',
  color: '#000000',
  fontWeight: 700,
});

export const SecondHeaderSubTitle = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: '21px',
  lineHeight: '31.5px',
  alignItems: 'center',
  textAlign: 'center',
  color: '#000000',
  fontWeight: 400,
});

export const StyledViewWebsiteButtonMain = styled(Button)({
  width: "280px",
  textTransform: 'uppercase',
  height: "40px !important",
  fontSize: "18px",
  borderRadius: '6px!important',
  fontWeight: 700,
  boxShadow: "none",
  fontFamily: 'Poppins',
  color: 'white',
  paddingTop: '5px',
  textDecoration: 'none',
  background: 'linear-gradient(to right, rgba(255, 107, 0, 1) 0%, rgba(173, 42, 0, 1) 100%)',
  '&:disabled': {
    background: 'rgba(224, 224, 224, 1)',
  }
});


export const MainContentWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: 'auto',
  minHeight: "87vh",
  background: '#E5E5E5',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    minHeight: "73vh",
  }
}));


export const MainContentWrapperMiddleContent = styled(Box)(({ theme }) => ({
  minHeight: "120vh",
}));

export const MainContentStepperBox = styled(Box)(({ theme }) => ({
  maxWidth: "420px",
  margin: "0 auto",
  padding: theme.spacing(1),
  maxHeight: "100%",
  justifyContent: "center",
  display: "flex",
}));

export const AddressValue = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 400,
  maxWidth: "280px",
  [theme.breakpoints.down(480)]: {
    maxWidth: "190px",
  }
}));
export const NameValue = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 400,
  maxWidth: "280px",
  [theme.breakpoints.down(480)]: {
    maxWidth: "190px",
  }
}));