import { styled, TextField, Typography } from "@mui/material";

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    // borderRadius: "24px",
    // background: "#FFF",
    // boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.20)",
    // height: "48px",
    // padding: `0 ${theme.spacing(1)}`,
    "& .MuiInputBase-input::placeholder": {
      fontFamily: 'Poppins !important',
    },
  },
}));

export const StyledLabel = styled(Typography)(({ theme }) => ({
  color: "#000000",
  fontSize: "12px",
  fontWeight: 400,
  // lineHeight: "22.5px" /* 143.75% */,
  letterSpacing: "0.15px",
  marginLeft: "23px",
  marginBottom: "4px",
  fontFamily: 'Poppins',
}));
