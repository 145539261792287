import { Box, Button, Grid, Link, Typography } from "@mui/material";
import {
  CutomImageWraper,
  StyledFooterBox,
  StyledTitle,
  StyledViewWebsiteButton,
} from "./styles";
import orders from "assets/img/orders.png";
import { useAppSelector } from "hooks/useReduxHook";
import { errorSelector } from "features/error";
import { useMatchMobile } from "hooks/useMatchMobile";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useNavigate } from "react-router-dom";
import sendToGTM from "./sendToGTM";
import React from "react";

const ErrorMessage = () => {
  const { isMatchMobile } = useMatchMobile();

  return (
    <Box sx={{ mb: "30px", mt: isMatchMobile ? 0 : 7 }}>
      <StyledTitle
        sx={{ fontSize: isMatchMobile ? "24px" : "30px",  lineHeight:  isMatchMobile ? '28.8px' : '36px', fontWeight: 500, fontFamily: 'Poppins' }}
      >
        Our AI Tool Was Not Able To Find Any Public Digital Ordering Solution
        For Your Restaurant.
      </StyledTitle>
      <br />
      <Typography sx={{ fontSize: isMatchMobile ? "16px" : "20px", fontFamily: 'Poppins' }}>
        No Worries, We Can Create One For In A Matter Of Days.{" "}
        <Link
          target="blank"
          sx={{
            fontFamily: 'Poppins',
            textAlign: "center",
            // color: "white",
            // ml: 1,
            // textDecorationColor: "white",
          }}
          href="https://orders.co/contact-us/"
        >
          Contact US
        </Link>{" "}
        To Learn How.
      </Typography>
    </Box>
  );
};

const NotFoundTimeout = () => {
  const { isMatchMobile } = useMatchMobile();
  const navigate = useNavigate();
  const goToLink = () => {
    const url = process.env.REACT_APP_VIEW_DEMO_LINK; // Replace with the URL you want to open
    window.open(url, "_blank");
  };
  const handleStartOver = () => {
    localStorage.removeItem("formData");
    const event = "oco.multiStepFormStepChange";
    const value = 9;
    const label = "Not Found to Step 1 (Not Found)";
    const gtmData = { value, label };
    sendToGTM(event, gtmData);
    navigate("/");
  };

  return (
    <Grid container justifyContent="center" alignContent={"center"}>
      <Grid item xs={12} md={12} lg={12} sm={12}>
        <ErrorMessage />
        <Box sx={{ mb: "30px" }}>
          <Typography sx={{ fontFamily: 'Poppins' }}>Want To View A Demo Menu?</Typography>
        </Box>
        <Box>
          <StyledViewWebsiteButton variant="contained" onClick={goToLink}>
            VIEW DEMO WEBSITE
          </StyledViewWebsiteButton>
        </Box>
        <StyledFooterBox sx={{ boxShadow: 4 }}>
          <Grid container m-0 p-0>
            <Grid item lg={6} md={12} xs={12} sm={12} m-0 p-0></Grid>
            <Grid item lg={6} md={12} xs={12} sm={12} m-0 p-0>
              <Button
                sx={{
                  height: "53px !important",
                  fontSize: "15px",
                  borderRadius: '6px!important',
                  textTransform: "none",
                  fontWeight: 700,
                  boxShadow: "none",
                  fontFamily: 'Poppins',
                  color: 'white',
                  background: 'linear-gradient(to right, rgba(255, 107, 0, 1) 0%, rgba(173, 42, 0, 1) 100%)',
                  '&:disabled': {
                    background: 'rgba(224, 224, 224, 1)',
                  }
                }}
                variant="contained"
                onClick={handleStartOver}
                startIcon={<RestartAltIcon />}
              >
                Start Over
              </Button>
            </Grid>
          </Grid>
        </StyledFooterBox>
      </Grid>
    </Grid>
  );
};

const NotFoundDefaultServer = () => {
  const { isMatchMobile } = useMatchMobile();
  const navigate = useNavigate();
  const goToLink = () => {
    const url = process.env.REACT_APP_VIEW_DEMO_LINK; // Replace with the URL you want to open
    window.open(url, "_blank");
  };

  const handleStartOver = () => {
    localStorage.removeItem("formData");
    const event = "oco.multiStepFormStepChange";
    const value = 9;
    const label = "Not Found to Step 1 (Not Found)";
    const gtmData = { value, label };
    sendToGTM(event, gtmData);
    navigate("/");
  };

  return (
    <Grid container justifyContent="center" alignContent={"center"}>
      <Grid item xs={12} md={12} lg={12} sm={12}>
        <ErrorMessage />
        <Box sx={{ mb: "30px" }}>
          <Typography sx={{ fontFamily: 'Poppins' }}>Want To View A Demo Menu?</Typography>
        </Box>
        <Box>
          <StyledViewWebsiteButton variant="contained" onClick={goToLink}>
            VIEW DEMO WEBSITE
          </StyledViewWebsiteButton>
        </Box>
        <StyledFooterBox sx={{ boxShadow: 4 }}>
          <Grid container m-0 p-0>
            <Grid item lg={6} md={12} xs={12} sm={12} m-0 p-0></Grid>
            <Grid item lg={6} md={12} xs={12} sm={12} m-0 p-0>
              <Button
                sx={{
                  height: "53px !important",
                  fontSize: "15px",
                  borderRadius: '6px!important',
                  textTransform: "none",
                  fontWeight: 700,
                  boxShadow: "none",
                  fontFamily: 'Poppins',
                  color: 'white',
                  background: 'linear-gradient(to right, rgba(255, 107, 0, 1) 0%, rgba(173, 42, 0, 1) 100%)',
                  '&:disabled': {
                    background: 'rgba(224, 224, 224, 1)',
                  }
                }}
                variant="contained"
                onClick={handleStartOver}
                startIcon={<RestartAltIcon />}
              >
                Start Over
              </Button>
            </Grid>
          </Grid>
        </StyledFooterBox>
      </Grid>
    </Grid>
  );
};

const NotFound = () => {
  const errorData = useAppSelector(errorSelector.errorData);

  if (errorData.isTimeout || errorData.isServer) {
    const event = "oco.multiStepFormStepChange";
    const value = 10;
    const label = "Generate Menu Timeout (Not Found)";
    const gtmData = { value, label };
    sendToGTM(event, gtmData);
    return <NotFoundTimeout />;
  }
  const event = "oco.multiStepFormStepChange";
  const value = 11;
  const label = "Generate Menu Error (Not Found)";
  const gtmData = { value, label };
  sendToGTM(event, gtmData);
  return <NotFoundDefaultServer />;
};

export default NotFound;
