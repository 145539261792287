import {Grid, Box, styled, Typography} from "@mui/material";

export const MainGridContainer = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  minHeight: 'calc(100vh - 170px)',
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    minHeight: '73vh',
  }
}));

export const MainGridContainerItemLeft = styled(Grid)(({ theme }) => ({
  background: "#EBEEF3",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
  display: "flex",
  // position: "relative",
}));

export const MainGridContainerItemRight = styled(Grid)(({ theme }) => ({
  // background: '#E5E5E5'
}));

export const MainGridContainerItemRightBox = styled(Box)(({ theme }) => ({
  // flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  // marginTop: theme.spacing(14),
  overflowY: "auto",
  // background: '#E5E5E5',
  [theme.breakpoints.down('lg')]: {
    justifyContent: "inherit",
    marginTop: theme.spacing(2),
  }
}));


export const MainGridContainerItemLeftBox = styled(Box)(({ theme }) => ({
  maxWidth: "593px",
  display: 'flex',
  justifyContent: "center",
  alignItems: "center",
  margin: '0 auto',
  padding: '0 16px',
  height: 'calc(100vh - 172px)',
}));

export const StyledTitle = styled(Typography)(() => ({
  color: "#1C1B1C",
  fontSize: "50px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "56.75px",
  textAlign: "left",
  fontFamily: 'Poppins',
}));