import { createSlice } from "@reduxjs/toolkit";
import { api } from "api";

const initialState = {
  // data: [],
  // loading: false,
  // manuDownloadData: {},
};

const storesSlice = createSlice({
  name: "stores",
  initialState,
  reducers: {
    // updateStores(state, action) {
    //   // state.data = action.payload;
    // },
  },
  extraReducers: (builder) => {
    // builder.addMatcher(api.endpoints.getMealMeStore.matchPending, (state) => {
    //   state.loading = true;
    // });
    // builder.addMatcher(
    //   api.endpoints.getMealMeStore.matchFulfilled,
    //   (state, { payload }) => {
    //     const stringifiedStores = JSON.stringify(payload.stores);
    //     localStorage.setItem("melMeStores", stringifiedStores);
    //     state.loading = false;
    //   }
    // );
    // builder.addMatcher(api.endpoints.getMealMeStore.matchRejected, (state) => {
    //   state.loading = false;
    // });
    // builder.addMatcher(
    //   api.endpoints.getMealMeMenuDownload.matchFulfilled,
    //   (state, { payload }) => {
    //     state.manuDownloadData = payload.body;
    //   }
    // );
  },
});

// export const { updateStores } = storesSlice.actions;

export const storesSelector = {
  // storeList: (state) => state.stores.data,
  // manuDownloadData: (state) => state.stores.manuDownloadData,
};

export default storesSlice;
