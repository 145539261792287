import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { Box, Button, Grid, Typography, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormContext } from "context/formContext";
import {
  AddressValue, NameValue,
  StyledAddressWrapper,
  StyledBackButton,
  StyledBusinessTitle,
  StyledFooterBox,
  StyledNextButton,
  StyledVerifyTitle,
} from "./styles";
import {
  useGetDoordashStoresListMutation,
  // useGetMealMeStoresListMutation,
} from "api";
import { setError } from "features/error";
import { useDispatch } from "react-redux";
import Preloader from "./Preloader/Preloader";
import { useMatchMobile } from "hooks/useMatchMobile";
import TermsAndUsePrivacyPolicy from "./TermsPrivacy";
import sendToGTM from "./sendToGTM";

const BusinessInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getStoresList, { isLoading }] = useGetDoordashStoresListMutation();
  const restaurantName =  localStorage.getItem('restaurantName');
  console.log(restaurantName, 'restaurantName');
  const { formData, updateFormData } = useContext(FormContext);
  const [restaurantList, setRestaurantList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const { isMatchMobile } = useMatchMobile();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goBack = () => {
    const event = "oco.multiStepFormStepChange";
    const value = 102;
    const label = "Step 2 to Step 1 (Business Info)";
    const gtmData = { value, label };
    sendToGTM(event, gtmData);
    navigate(-1);
  };

  const geNext = () => {
    if (formData._id === null) {
      dispatch(
        setError({
          title: "",
          errorMessage: true,
          isServer: false,
          isTimeout: false,
        })
      );
    } else {
      if (!formData._id) {
        setErrorMessage("Please Select Your Business");
      } else {
        const event = "oco.multiStepFormStepChange";
        const value = 2;
        const label = "Step 2 to Step 3 (Business Info)";
        const gtmData = { value, label, data: formData };
        sendToGTM(event, gtmData);

        navigate("/emailInfo");
        setErrorMessage("");
      }
    }
  };

  useEffect(() => {
    if (formData.restaurantName) {
      try {
        const dataModel = {
          restaurantName: formData.restaurantName,
          addressInfo: formData.addressInfo,
        };
        getStoresList(dataModel)
          .unwrap()
          .then((res) => {
            if (!res.error) {
              setRestaurantList(res);
              updateFormData(res[0]);
            }
          })
          .catch((error) => {
            dispatch(
              setError({
                title: "",
                errorMessage: true,
                isServer: true,
                isTimeout: false,
              })
            );
          });
      } catch (error) {
        console.error(error);

        // Expected output: ReferenceError: nonExistentFunction is not defined
        // (Note: the exact output may be browser-dependent)
      }
    }
  }, []);

  const handleSelectBusiness = (restaurant) => {
    const event = "oco.businessSelected";
    const value = 300;
    const label = "Business Selected";
    const gtmData = { value, label, data: restaurant };
    sendToGTM(event, gtmData);
    updateFormData(restaurant);
  };

  // const styleCheck = isMatchMobile
  //   ? {
  //     height: "100vh",
  //     overflow: "auto",
  //     mt: 3,
  //     display: "flex",
  //     flexDirection: "column",
  //     px: 0.5,
  //   }
  //   : { mt: 3, px: 0.5 };

  return (
    <Box>
      {restaurantList.length ? (
        <StyledBusinessTitle sx={{ fontSize: "36px" }}>
          Select Your Business
        </StyledBusinessTitle>
      ) : null}
      <Box
      // sx={{
      //   display: "flex",
      //   justifyContent: "space-between",
      //   flexDirection: "column",
      // }}
      >
        {isLoading && <Preloader overlay />}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3, mt: 3 }}>
          {restaurantList.map((item) => {
            return (
              <StyledAddressWrapper
                key={item._id}
                onClick={() => handleSelectBusiness(item)}
                isSelected={Boolean(item._id === formData._id)}
                sx={{ boxShadow: 3 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    padding: 2,
                  }}
                >
                  <Typography
                    sx={{
                      minWidth: "100px",
                      textAlign: "left",
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: '14px',
                      fontWeight: 700,
                    }}
                  >
                    <b>Name:</b>
                  </Typography>
                  <NameValue
                    sx={{
                     fontFamily: 'Poppins',
                     fontSize: '14px',
                     fontWeight: 400,
                    }}
                    align="left"
                  >
                    {restaurantName} <br />
                    <small>{`(${item.restaurantName})`}</small>
                  </NameValue>
                </Box>
                <Divider
                  sx={{
                    background: Boolean(item._id === formData._id) ?
                  `white` : '#C4C4C4',
                    height: '0.5px',
                    width: '100%',
                }}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    justifyContent: "flex-start",
                    width: "100%",
                    padding: 2,
                  }}
                >
                  <Typography
                    sx={{
                      minWidth: "100px",
                      textAlign: "left",
                      fontFamily: 'Poppins',
                      fontSize: '14px',
                      fontWeight: 700,
                    }}
                  >
                    <b>Address:</b>
                  </Typography>
                  <AddressValue align="left">
                    {item?.addressInfo?.address1}
                    <br />
                    {`${item?.addressInfo?.city || ""} ${
                      item?.addressInfo?.state || ""
                    } ${item?.addressInfo?.zipCode || ""}`}
                  </AddressValue>
                </Box>
              </StyledAddressWrapper>
            );
          })}
          {restaurantList.length ? (
            <StyledAddressWrapper
              key={uuidv4()}
              onClick={() => handleSelectBusiness({ _id: null })}
              isSelected={Boolean(formData?._id === null)}
              sx={{ boxShadow: 3 }}
            >
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "center",
                  padding: '20px 0',
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: '14px',
                  fontWeight: 400,
                }}
              >
                None of Above
              </Typography>
            </StyledAddressWrapper>
          ) : null}
        </Box>
        <Box
          sx={{
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          {errorMessage && (
            <Typography color="error">{errorMessage}</Typography>
          )}
        </Box>
      </Box>

      <Box sx={{ mt: 2 }}>
        <StyledFooterBox sx={{ boxShadow: 4 }}>
          <Grid container m-0 p-0>
            <Grid item lg={6} md={12} xs={12} sm={12} m-0 p-0></Grid>
            <Grid item lg={6} md={12} xs={12} sm={12} m-0 p-0 sx={{ mt: 2 }}>
              <StyledBackButton variant="outlined" onClick={goBack}>
                Back
              </StyledBackButton>
              <StyledNextButton variant="contained" onClick={geNext}>
                Next
              </StyledNextButton>
              <TermsAndUsePrivacyPolicy />
            </Grid>
          </Grid>
        </StyledFooterBox>
      </Box>
    </Box>
  );
};

export default BusinessInfo;
