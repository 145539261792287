import React, { useState, useEffect } from "react";
import { TextField, Box } from "@mui/material";
import { GOOGLE_API_KEY } from "constants/config";
import { usePlacesWidget } from "react-google-autocomplete";
import { StyledTextField } from "./styles";
import CustomTextField from "./CustomTextField";

export default function GooglAutocomplete({
  name,
  required,
  readOnly = false,
  initialValue = "",
  getAddressFields = () => {},
  formSubmitted = false,
  onChangeInput = () => {},
  inputValue = "",
  label,
  placeholder,
  ...props
}) {
  const [value, setValue] = useState(inputValue || initialValue);
  const [error, handleError] = useState("");

  const handleChangeInput = (e) => {
    const { value } = e.currentTarget;
    onChangeInput(value);
    if (required) {
      if (!value) {
        handleError("This field is required");
      } else {
        handleError("");
      }
    }
    setValue(value);
  };

  useEffect(() => {
    if (formSubmitted) {
      if (!value) {
        handleError("This field is required");
      } else {
        handleError("");
      }
    }
  }, [readOnly, formSubmitted, value, required]);

  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    onPlaceSelected: (places) => {
      if (places) {
        const { formatted_address, geometry, name, formatted_phone_number } =
          places;
        const components = places.address_components;
        const addressInfo = {
          address1: "",
          address2: "",
          city: "",
          state: "",
          country: "",
          zipCode: "",
          address: formatted_address,
          fullAddress: formatted_address,
          geoLocation: {
            lat: geometry?.location?.lat(),
            lng: geometry?.location?.lng(),
            debug: {
              ...places,
            },
          },
        };

        if (components) {
          for (var i = 0, l = components.length; i < l; i++) {
            var component = components[i];
            if (component.types && component.types.indexOf("route") !== -1) {
              const streetNumber = components.find(
                (item) => item.types.indexOf("street_number") !== -1
              );
              addressInfo.address1 = streetNumber
                ? `${streetNumber?.long_name} ${component.long_name}`
                : component.long_name;
            }
            if (component.types && component.types.indexOf("locality") !== -1) {
              addressInfo.city = component.long_name;
            }
            if (
              component.types &&
              component.types.indexOf("administrative_area_level_1") !== -1
            ) {
              addressInfo.state = component.short_name;
            }
            if (component.types && component.types.indexOf("country") !== -1) {
              addressInfo.country = component.long_name;
            }
            if (
              component.types &&
              component.types.indexOf("postal_code") !== -1
            ) {
              addressInfo.zipCode = component.long_name;
            }
            if (
              component.types &&
              component.types.indexOf("subpremise") !== -1
            ) {
              addressInfo.address2 = component.long_name;
            }
          }
        }

        // addressInfo.fullAddress = "";
        // if (addressInfo.address1) {
        //   addressInfo.fullAddress = `${addressInfo.address1},`;
        // }
        // if (addressInfo.city) {
        //   addressInfo.fullAddress += `${addressInfo.city}, `;
        // }
        // if (addressInfo.state || addressInfo.zipCode) {
        //   addressInfo.fullAddress += `${addressInfo.state} ${addressInfo.zipCode} ? "," : ""} `;
        // }
        // if (addressInfo.country) {
        //   addressInfo.fullAddress += `${addressInfo.country}`;
        // }

        getAddressFields({
          addressInfo,
          name,
          businessPhone: formatted_phone_number
            ? formatted_phone_number
                .replaceAll("(", "")
                .replaceAll(")", "")
                .replaceAll("-", "")
                .replaceAll(" ", "")
                .replaceAll("+", "")
            : "",
        });
        setValue(name);
      }
    },
    options: {
      componentRestrictions: { country: "us" },
      types: ["food"],
      fields: [
        "name",
        "rating",
        "formatted_phone_number",
        "formatted_address",
        "address_components",
        "geometry",
        "opening_hours",
        "photos",
        "reviews",
        "price_level",
        "icon_mask_base_uri",
        "icon_background_color",
        "icon",
        "business_status",
        "url",
        "user_ratings_total",
        "website",
        "vicinity",
        "types",
      ],
    },
  });

  useEffect(() => {
    setValue(inputValue || initialValue);
  }, [inputValue, initialValue, readOnly]);

  return (
    <Box sx={{ position: "relative", height: '64px' }}>
      <TextField
        error={Boolean(error) && !readOnly}
        helperText={!readOnly ? error : ""}
        fullWidth
        inputRef={ref}
        size="middle"
        name={name}
        label={label}
        onChange={handleChangeInput}
        // disabled={readOnly}
        sx={{
          '& label': { fontFamily: 'Poppins, sans-serif' }, // Customizes the font family of the label
          '& input': { fontFamily: 'Poppins, sans-serif' }, // Customizes the font family of the input field
        }}
        inputProps={{
          sx: {
            width: "100%",
            '& label': { fontFamily: 'Poppins' },
            '& input': { fontFamily: 'Poppins' }
          },
          value: value,
        }}
        // defaultValue={value}
        InputLabelProps={{ shrink: !!value.length }}
        {...props}
        placeholder={placeholder}
      />
    </Box>
  );
}
