export const removeItemAfterSignUp = () => {
  localStorage.removeItem("accountInfo");
  localStorage.removeItem("legalInfo");
  localStorage.removeItem("currentPlan");
  localStorage.removeItem("billingInfo");
  localStorage.removeItem("billingId");
  localStorage.removeItem("serviceInfo");
  localStorage.removeItem("companyType");
  localStorage.removeItem("accountResponse");
  localStorage.removeItem("business");
  localStorage.removeItem("currentInvitationService");
  localStorage.removeItem("approvedInvitationInfo");
  localStorage.removeItem("legalResponseInfo");
  localStorage.removeItem("accountInfoAuth");
  localStorage.removeItem("currentSetupFee");
};

/* temporary version */
export const handleDeleteValues = (values) => {
  delete values?.address;
  delete values?.address1;
  delete values?.address2;
  delete values?.city;
  delete values?.country;
  delete values?.county;
  delete values?.fullAddress;
  delete values?.geoLocation;
  delete values?.name;
  delete values?.state;
  delete values?.zipCode;
  return values;
};

export const collectGoogleQueryData = (places) => {
  const { formatted_address, geometry } = places;
  const components = places.address_components;
  const addressInfo = {
    address1: formatted_address || "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
    address: formatted_address,
    fullAddress: formatted_address,
    geoLocation: {
      lat: geometry?.location?.lat(),
      lng: geometry?.location?.lng(),
      debug: {
        ...places,
      },
    },
  };
  if (components) {
    for (var i = 0, l = components.length; i < l; i++) {
      var component = components[i];
      if (component.types && component.types.indexOf("route") !== -1) {
        const streetNumber = components.find(
          (item) => item.types.indexOf("street_number") !== -1
        );
        addressInfo.address1 = streetNumber
          ? `${streetNumber?.long_name} ${component.long_name}`
          : component.long_name;
      }
      if (component.types && component.types.indexOf("locality") !== -1) {
        addressInfo.city = component.long_name;
      }

      if (
        component.types &&
        component.types.indexOf("administrative_area_level_1") !== -1
      ) {
        addressInfo.state = component.short_name;
      }
      if (component.types && component.types.indexOf("country") !== -1) {
        addressInfo.country = component.long_name;
      }
      if (component.types && component.types.indexOf("postal_code") !== -1) {
        addressInfo.zipCode = component.long_name;
      }
      if (component.types && component.types.indexOf("subpremise") !== -1) {
        addressInfo.address2 = component.long_name;
      }
    }
  }
  // addressInfo.fullAddress = "";
  // if (addressInfo.address1 || addressInfo.address2) {
  //   addressInfo.fullAddress = `${addressInfo.address1} ${addressInfo.address2}, `;
  // }
  // if (addressInfo.city) {
  //   addressInfo.fullAddress += `${addressInfo.city}, `;
  // }
  // if (addressInfo.state || addressInfo.zipCode) {
  //   addressInfo.fullAddress += `${addressInfo.state ? addressInfo.state : ''}${addressInfo.zipCode ? ' ' + addressInfo.zipCode : ''}${addressInfo.country ? ', ' + addressInfo.country : ''}`;
  // }
  // if (addressInfo.country) {
  //   addressInfo.fullAddress += `${addressInfo.country}`;
  // }
  console.log(addressInfo, 'addressInfo');
  return addressInfo;
};
