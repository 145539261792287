import { memo } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { PreLoaderContainer, PreloaderBlock } from "./styles";

const Preloader = ({ overlay, sx = {} }) => {
  // marginLeft: '129px',
  return (
    <PreLoaderContainer
      sx={{ ...sx }}
      className={`${overlay ? "overlay" : ""}`}
    >
      <PreloaderBlock>
        <CircularProgress />
      </PreloaderBlock>
    </PreLoaderContainer>
  );
};

export default memo(Preloader);
