import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Divider,
  Fade,
  Grid,
  Link,
  Snackbar,
  Typography,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomTextField from "components/FormFields/CustomTextField";
import { useLocation, useNavigate } from "react-router-dom";
import { SignUpPathEnum } from "constants/signUpPath";
import mailImg from "assets/img/mail.png";
import {StyledEmail, StyledFooterBox, StyledBackButton, StyledNextButton, StyledVerifyDescription, StyledVerifyTitle} from "./styles";
import { FormContext } from "context/formContext";
import {
  useEmailVerifyMutation,
  useGetDoordashMenuDownloadMutation,
  // useGetMealMeMenuDownloadMutation,
} from "api";
import Preloader from "./Preloader/Preloader";
import TermsAndUsePrivacyPolicy from "./TermsPrivacy";
import sendToGTM from "./sendToGTM";

const initialDataModel = {
  verificationCode: "",
};

const formSchema = yup.object().shape({
  verificationCode: yup.string().required("This field is required"),
});

const EmailVerify = () => {
  const { formData, updateFormData } = useContext(FormContext);
  const [showMessage, setShowMessage] = useState(false);

  const [loading, setLaoding] = useState(false);
  const [getDoordashMenuDownload, { isLoading }] =
    useGetDoordashMenuDownloadMutation();
  const [errorMessage, setErrorMessage] = useState("");
  const [verifyEmail] = useEmailVerifyMutation();
  const navigate = useNavigate();
  const location = useLocation();

  const formMethods = useForm({
    mode: "all",
    defaultValues: initialDataModel,
    resolver: yupResolver(formSchema),
  });

  const {
    formState: { isSubmitted },
    reset,
    getValues,
  } = formMethods;

  const handleSubmit = async (data) => {
    try {
      if (formData) {
        const dataObj = {
          storeId: formData?._id,
          googlePlaceId: formData?.googlePlaceId,
          email: formData?.email,
          verificationCode: data.verificationCode,
        };
        try {
          const res = await getDoordashMenuDownload(dataObj).unwrap();
          setErrorMessage("");
          // setId(res?.data?.body?.storeId || "");
          const event = "oco.multiStepFormStepChange";
          const value = 4;
          const label = "Step 4 to Step 5 (Email Verify)";
          const gtmData = { value, label, data: data };
          sendToGTM(event, gtmData);
          updateFormData(data);
          navigate(SignUpPathEnum.SIGN_UP_SUCCESS, {
            state: { id: res?.body?.storeId },
          });
        } catch (err) {
          setErrorMessage(true);
          console.log(err);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage("");
    },3000)
  },[errorMessage])

  const goBack = () => {
    const event = "oco.multiStepFormStepChange";
    const value = 104;
    const label = "Step 4 to Step 3 (Email Verify)";
    const gtmData = { value, label };
    sendToGTM(event, gtmData);
    navigate(-1);
  };

  useEffect(() => {
    const dataFromStorage = localStorage.getItem("formData");
    const parsedData = JSON.parse(dataFromStorage);
    if (parsedData) {
      const dataModel = {
        email: parsedData.email,
      };
      reset(dataModel);
    }
  }, [location]);

  const sendLink = async () => {
    const event = "oco.passwordResend";
    const value = 304;
    const label = "Resend Password (Email Verify)";
    const gtmData = { value, label };
    sendToGTM(event, gtmData);
    sendToGTM(event, gtmData);
    setLaoding(true);

    try {
      const dataModel = {
        lastName: formData.lastName,
        firstName: formData.firstName,
        restaurantName: formData.restaurantName,
        email: formData.email,
        addressInfo: formData.addressInfo,
      };
      await verifyEmail(dataModel).unwrap();
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, [3000]);
      setLaoding(false);
    } catch (error) {
      console.error(error);
      setLaoding(false);
    }
  };
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={12} lg={12} sm={12}>
        {isLoading || loading ? <Preloader overlay /> : null}
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Box
              sx={{
                mt: 8,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                // justifyContent: "center",
                // alignItems: "center",
                height: "600px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "112px", height: "115px" }}>
                  <StyledEmail src={mailImg} alt={"Send Email"} />
                </Box>
                <Box sx={{ maxWidth: "500px" }}>
                  <StyledVerifyTitle>
                    Verify Your Email
                  </StyledVerifyTitle>
                  <StyledVerifyDescription>
                    Enter The Code Sent To Your Email Below To Complete The
                    Email Verification!
                  </StyledVerifyDescription>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: "30px",
                }}
              >
                {errorMessage && (
                  <div
                    className={`message-container ${
                      errorMessage ? "show fade-in" : ""
                    }`}
                    style={{ fontFamily: 'Poppins' }}
                  >
                    <Typography color={"error"} align="left" sx={{ fontFamily: 'Poppins' }}>
                      Wrong Verification Code
                    </Typography>
                  </div>
                )}

                <div
                  className={`message-container ${
                    showMessage ? "show fade-in" : ""
                  }`}
                  style={{ fontFamily: 'Poppins' }}
                >
                  <Typography
                    color="#4caf50"
                    variant="body1"
                    className="message-text"
                    sx={{ fontFamily: 'Poppins' }}
                  >
                    A New Code Has Been Sent
                  </Typography>
                </div>
              </Box>
              <CustomTextField
                label="Provide Verify Code"
                name="verificationCode"
                sx={{ minWidth: "300px" }}
              />

              <Box sx={{ height: "48px" }}>
                <Typography sx={{ fontFamily: 'Poppins' }}>
                  Didn’t Get A Code?
                  <Button variant="text" onClick={sendLink} sx={{ fontFamily: 'Poppins' }}>
                    <b style={{ fontWeight: 700, fontFamily: 'Poppins' }}>Click to resend</b>
                  </Button>
                </Typography>
              </Box>
            </Box>
            <StyledFooterBox sx={{ boxShadow: 4 }}>
              <Grid container m-0 p-0>
                <Grid item lg={6} md={12} xs={12} sm={12} m-0 p-0></Grid>
                <Grid item lg={6} md={12} xs={12} sm={12} m-0 p-0 sx={{ mt: 2 }}>
                  <StyledBackButton variant="outlined" onClick={goBack}>
                    Back
                  </StyledBackButton>
                  <StyledNextButton
                      variant="contained" type="submit"
                      disabled={!formMethods.formState.isDirty}>
                    Next
                  </StyledNextButton>
                  <TermsAndUsePrivacyPolicy />
                </Grid>
              </Grid>
            </StyledFooterBox>
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default EmailVerify;
