import React, { useState, useEffect, useContext } from "react";
import Success from "./Success";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { SignUpPathEnum } from "constants/signUpPath";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import NameInfo from "./NameInfo";
import BusinessInfo from "./BusinessInfo";
import EmailInfo from "./EmailInfo";
// import { StepperSlider } from "components/styles";
// import { Step, StepLabel } from "@mui/material";
import { FormContext } from "context/formContext";
import {MainContentStepperBox, StyledTitle} from "components/styles";
import AddressInfo from "./AddresInfo";
import NotFound from "./NotFound";
import { useAppSelector } from "hooks/useReduxHook";
import errorSlice, { errorSelector } from "features/error";
import EmailVerify from "./EmailVerify";
import Survey from "components/Survey";
import {Generated} from "./Generated/Generated";
import {Container} from "@mui/material";
import {useMatchMobile} from "../hooks/useMatchMobile";

// import TypingEffect from "components/TypingEffect";

// const titleMapping = (path) => {
//   const data = {
//     nameInfo: "Let’s Get Started",
//     businessInfo: "",
//     emailInfo: "",
//     emailVerify: "",
//   };
//   return {};
// };

const SIGN_UP_FLOW = {
  NAME_INFO: 1,
  BUSINESS_INFO: 2,
  EMAIL_INFO: 3,
  SUCCESS: 4,
};

const Stepper = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isBusinessListPage = location.pathname === "/businessInfo";
  const errorData = useAppSelector((state) => state.error);
  const { isMatchMobile } = useMatchMobile();
  // const errorData = useAppSelector(errorSelector.errorData);

  useEffect(() => {
    if (errorData?.data?.errorMessage) {
      navigate("/notFound");
    }
  }, [errorData?.data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // if (errorData.errorMessage) {
  //   return <NotFoundTimeout />;
  // }

  return (
    <Container component="main" maxWidth="xs" className={'main-container'}>
      <Routes>
        <Route path={"/"} element={<NameInfo />} />
        <Route path={SignUpPathEnum.BUSINESS_INFO} element={<BusinessInfo />} />
        <Route path={SignUpPathEnum.EMAIL_INFO} element={<EmailInfo />} />
        <Route path={SignUpPathEnum.EMAIL_VERIFY} element={<EmailVerify />} />
        <Route path={SignUpPathEnum.ADDRESS_INFO} element={<AddressInfo />} />
        <Route path={"/survey"} element={<Survey />} />
        <Route path={SignUpPathEnum.SIGN_UP_SUCCESS} element={<Success />} />
        <Route path={"/*"} element={<NotFound />} />
      </Routes>
    </Container>
  );
};

export default Stepper;
