import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  data: { errorMessage: "", errorTitle: "", isServer: false, isTimeout: false },
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError: (state, { payload }) => {
      state.data = {
        ...initialState.data,
        ...payload,
      };
    },
    clearError: (state) => {
      state.data = initialState;
    },
  },
});

export const errorSelector = {
  errorData: (state) => state.error.data,
};

export const { setError, clearError } = errorSlice.actions;
export default errorSlice;
