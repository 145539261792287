import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    mode: "light",

    primary: {
      main: "#ff6b00",
    },

    secondary: {
      main: "#1976d2",
    },
    warning: {
      main: "#ed6c02",
    },
    // success: {

    // },
    text: {
      primary: "#2c405a",
      secondary: "#657786",
    },
    actions: {},
    background: {
      default: "#F2F2F2",
      paper: "#ffffff",
    },
  },
  typography: {
    // Correct place to override the font family globally
    typography: {
      fontFamily: 'Poppins, sans-serif',
    },
  },
  // Override custom
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root': {
            fontFamily: 'Poppins !important',
            color: '#000000',
          }
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // background: 'white',
          "& .MuiInputBase-root": {
            background: "white",
            fontFamily: 'Poppins !important',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        color: '#000000',
        focused: { // Apply focused styles
          color: '#000000',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: "140px",
          height: "53px !important",
          fontSize: "15px",
          borderRadius: '6px',
          textTransform: "none",
          fontWeight: 700,
          boxShadow: "none",
          fontFamily: 'Poppins',
          background: 'linear-gradient(to bottom, rgba(255, 107, 0, 1) 0%, rgba(173, 42, 0, 1) 100%)',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        cell: {
          "&:focus": { outline: "none" },
        },
      },
    },
    AppBar: {
      styleOverrides: {
        root: {
          zIndex: 2,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          marginBottom: "1rem",
        },
      },
    },
  },
});

export default theme;
