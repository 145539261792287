export const endpoints = {
  /** Auth */
  getMealMeStore: (id) => `/meal-me/menu/store/${id}`,
  getMealMeStoresList: () => `/meal-me/menu/stores`,
  getMealMeMenuDownload: () => `/meal-me/menu/download`,
  getDoordashMenuDownload: () => `/meal-me/menu/doordash/download`,
  emailVerify: () => `/meal-me/menu/send/verification-email`,
  generateCount: () => `/meal-me/menu/generated-count`,
  getDoordashStoresList: () => `/meal-me/menu/doordash/stores`,
};
