import { isRejectedWithValue } from "@reduxjs/toolkit";
import { setError } from "features/error";
import store from "store";

// import { toast } from "react-toastify";
// import { showMessage } from "utils/message";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    // if (action.payload.status !== 404) {
    if (action.payload.status !== 403) {
      if (action.payload.status === 206) {
        store.dispatch(
          setError({
            title: "",
            errorMessage: true,
            isServer: false,
            isTimeout: false,
          })
        );
      } else {
        store.dispatch(
          setError({
            title: "",
            errorMessage: true,
            isServer: true,
          })
        );
      }
    }
    // }
  }
  return next(action);
};
