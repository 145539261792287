import React, { useState, useEffect, useContext } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { SignUpPathEnum } from "constants/signUpPath";
import GoogleAutocomplate from "components/FormFields/GoogleAutocomplate";
import { FormContext } from "context/formContext";
import { StyledFooterBox, StyledTitle, StyledBackButton, StyledNextButton } from "./styles";
import TermsAndUsePrivacyPolicy from "./TermsPrivacy";

const initialDataModel = {
  addressInfo: { fullAddress: "" },
};

const formSchema = yup.object().shape({
  addressInfo: yup.object({
    // city: yup.string().required("This field is required").trim(),
    // state: yup.string().required("This field is required").trim(),
    // zipCode: yup.string().required("This field is required").trim(),
    // address1: yup.string().required("This field is required").trim(),
    // country: yup.string().required("This field is required").trim(),
    fullAddress: yup.string().required("This field is required"),
  }),
});

const AddressInfo = () => {
  const { updateFormData } = useContext(FormContext);
  const [isValidGoogleValue, setIsValidGoogleValue] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const formMethods = useForm({
    mode: "all",
    defaultValues: initialDataModel,
    resolver: yupResolver(formSchema),
  });
  const {
    formState: { isSubmitted },
    reset,
    getValues,
  } = formMethods;
  const currentModel = getValues();

  const handleSubmit = async (data) => {

    if (isValidGoogleValue) {
      updateFormData(data);
      navigate(SignUpPathEnum.BUSINESS_INFO);
    }
  };

  useEffect(() => {
    const dataFromStorage = localStorage.getItem("formData");
    const parsedData = JSON.parse(dataFromStorage);
    if (parsedData) {
      reset({
        addressInfo: {
          fullAddress: parsedData?.addressInfo?.fullAddress || "",
        },
      });
    }
  }, [location]);

  const onChangeAddressInput = (value) => {
    reset({
      addressInfo: { fullAddress: value },
    });
  };

  const getAddressFields = (data) => {
    reset(data);
  };

  const hanldeInvalidSubmit = (errr) => {
    console.log("errr", errr);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Grid container justifyContent="center" alignContent={"center"}>
      <Grid item xs={12} md={12} lg={12} sm={12} sx={{}}>
        <Box sx={{ mb: 8 }}>
          <StyledTitle>Let's find your restaurant </StyledTitle>
        </Box>
        <FormProvider {...formMethods}>
          <form
            onSubmit={formMethods.handleSubmit(
              handleSubmit,
              hanldeInvalidSubmit
            )}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                minWidth: "500px",
                width: "100%",
              }}
            >
              <GoogleAutocomplate
                setIsValidGoogleValue={setIsValidGoogleValue}
                getAddressFields={getAddressFields}
                onChangeAddressInput={onChangeAddressInput}
                inputValue={currentModel.addressInfo.fullAddress || ""}
                initialValue={currentModel.addressInfo.fullAddress || ""}
                name="addressInfo"
                label="Address Information"
                required={true}
                formSubmitted={isSubmitted}
                placeholder="Search your Restaurant Address"
              />
            </Box>
            <StyledFooterBox sx={{ boxShadow: 4 }}>
              <Grid container m-0 p-0>
                <Grid item lg={6} md={12} xs={12} sm={12} m-0 p-0></Grid>
                <Grid item lg={6} md={12} xs={12} sm={12} m-0 p-0>
                  <StyledBackButton variant="outlined" type="submit" onClick={goBack}>
                    Back
                  </StyledBackButton>
                  <StyledNextButton variant="contained" type="submit">
                    Next
                  </StyledNextButton>
                  <TermsAndUsePrivacyPolicy />
                </Grid>
              </Grid>
            </StyledFooterBox>
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default AddressInfo;
