import React, { useState, useEffect } from "react";
import { TextField, Box } from "@mui/material";
import { GOOGLE_API_KEY } from "constants/config";
import { usePlacesWidget } from "react-google-autocomplete";
import { geocodeByAddress } from "react-google-places-autocomplete";
import { collectGoogleQueryData } from "utils/soldOut";
import CustomTextField from "./CustomTextField";

export default function GooglAutocomplete({
  name,
  required,
  isSignUp = false,
  readOnly = false,
  initialValue = "",
  getAddressFields = () => {},
  formSubmitted = false,
  onChangeAddressInput = () => {},
  inputValue = "",
  setIsValidGoogleValue = () => {},
  label,
  ...props
}) {
  const [value, setValue] = useState(inputValue || initialValue);
  const [error, handleError] = useState("");
  const [notFoundAddress, setNotFoundAddress] = useState("");

  const handleChangeInput = (e) => {
    const { value } = e.currentTarget;
    const autocomplete = new window.google.maps.places.AutocompleteService();

    onChangeAddressInput(value);
    if (required) {
      if (!value.length) {
        setIsValidGoogleValue(false);
        handleError("This field is required");
      } else {
        autocomplete.getPredictions({ input: value }, (predictions) => {
          if (!predictions?.length) {
            setNotFoundAddress("No address found");
          } else {
            setNotFoundAddress("");
            setIsValidGoogleValue(true);
            handleError("");
          }
        });
      }
    }
    setValue(value);
  };

  useEffect(() => {
    onChangeAddressInput(value);
    if (required) {
      if (formSubmitted) {
        if (!value) {
          setIsValidGoogleValue(false);
          handleError("This field is required");
        } else {
          setIsValidGoogleValue(true);
          handleError("");
        }
      }
    }
  }, [readOnly, formSubmitted, value]);

  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    onPlaceSelected: (places) => {
      const data = collectGoogleQueryData(places);
      getAddressFields({ addressInfo: data });
      setValue(data?.fullAddress);
    },
    options: {
      types: "regions",
    },
  });

  const handlesBlur = (event) => {
    geocodeByAddress(event.target.value)
      .then((results) => {
        handleError("");
        const data = collectGoogleQueryData(results[0]);
        getAddressFields({ addressInfo: data });
        setValue(data?.fullAddress);
      })
      .catch(() =>
        handleError("The address is wrong. Please provide a correct address.")
      );
  };

  useEffect(() => {
    handleError("");
    setValue(inputValue || initialValue);
  }, [inputValue, initialValue, readOnly]);

  return (
    <Box sx={{ position: "relative", opacity: readOnly ? 0.5 : 1 }}>
      <CustomTextField
        onBlur={handlesBlur}
        error={Boolean(error) && !readOnly}
        helperText={!readOnly ? error : ""}
        fullWidth
        inputRef={ref}
        size="small"
        name={name}
        label={label}
        onChange={handleChangeInput}
        // disabled={readOnly}
        inputProps={{
          sx: { width: "100%" },
          value: value,
        }}
        // defaultValue={value}
        InputLabelProps={{ shrink: !!value.length, onBlur: handlesBlur }}
        {...props}
      />
      {notFoundAddress && !error ? (
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            left: "0px",
            p: 1,
            color: "#999696",
          }}
          className="pac-container"
        >
          {notFoundAddress}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}
