import loc1 from "assets/img/svg/loc1.svg";
import loc2 from "assets/img/svg/loc2.svg";
import loc3 from "assets/img/svg/loc3.svg";

export const surveyData = [
  {
    title: "How many locations do you have?",
    options: [
      {
        title: "1",
        description: "asdfasdfa sasdf dsa dfa sdfasd fasdfasdf",
        icon: loc1,
      },
      {
        title: "1-4",
        description: "asdfasdfa sasdf dsa dfa sdfasd fasdfasdf",
        icon: loc2,
      },
      {
        title: "5-9",
        description: "asdfasdfa sasdf dsa dfa sdfasd fasdfasdf",
        icon: loc3,
      },
      {
        title: "10+",
        description: "asdfasdfa sasdf dsa dfa sdfasd fasdfasdf",
        icon: loc3,
      },
    ],
    isMulty: false,
  },
  {
    title: "How many delivery apps are you currently using?",
    options: [
      {
        title: "1",
        description: "asdfasdfa sasdf dsa dfa sdfasd fasdfasdf",
        icon: loc1,
      },
      {
        title: "3",
        description: "asdfasdfa sasdf dsa dfa sdfasd fasdfasdf",
        icon: loc2,
      },
      {
        title: "4",
        description: "asdfasdfa sasdf dsa dfa sdfasd fasdfasdf",
        icon: loc3,
      },
      {
        title: "4+",
        description: "asdfasdfa sasdf dsa dfa sdfasd fasdfasdf",
        icon: loc3,
      },
    ],
    isMulty: false,
  },
  {
    title: "Do you offer direct online ordering?",
    options: [
      {
        title: "Yes",
        description: "asdfasdfa sasdf dsa dfa sdfasd fasdfasdf",
        icon: loc1,
      },
      {
        title: "No",
        description: "asdfasdfa sasdf dsa dfa sdfasd fasdfasdf",
        icon: loc2,
      },
    ],
    isMulty: false,
  },
  {
    title: "Are you using an integration  service to consolidate your tablets?",
    options: [
      {
        title: "Yes",
        description: "asdfasdfa sasdf dsa dfa sdfasd fasdfasdf",
        icon: loc1,
      },
      {
        title: "No",
        description: "asdfasdfa sasdf dsa dfa sdfasd fasdfasdf",
        icon: loc2,
      },
    ],
    isMulty: false,
  },
  {
    title:
      "List any methods you’ve tried to promote your online ordering channels",
    description: "Select all that apply",
    options: [
      {
        title: "Social Media",
        description: "",
        icon: loc1,
      },
      {
        title: "Email Marketing",
        description: "",
        icon: loc2,
      },
      {
        title: "SMS Marketing",
        description: "",
        icon: loc2,
      },
      {
        title: "Loyalty Programs",
        description: "",
        icon: loc2,
      },
      {
        title: "Review Sites",
        description: "",
        icon: loc2,
      },
      {
        title: "None of the Above",
        description: "",
        icon: loc2,
      },
    ],
    isMulty: true,
  },
];
