const { Box, Typography, Link } = require("@mui/material");

const TermsAndUsePrivacyPolicy = () => {
  return (
    <Box sx={{ mt: 1 }}>
      <Typography sx={{ color: "#5a5a5acf" }}>
        <Link
          sx={{
            fontSize: "12px",
            textDecoration: "none",
            color: "#7A7A7A",
            fontWeight: 500,
            lineHeight: '18px',
            fontFamily: 'Poppins, sans-serif',
          }}
          target="_blank"
          href={`https://orders.co/terms-of-use/`}
        >
          Terms of use
        </Link>{" "}
        &#124;{" "}
        <Link
          sx={{
            fontSize: "12px",
            textDecoration: "none",
            color: "#7A7A7A",
            fontWeight: 500,
            lineHeight: '18px',
            fontFamily: 'Poppins, sans-serif',
          }}
          target="_blank"
          href={`https://orders.co/privacy-policy/`}
        >
          Privacy Policy
        </Link>
      </Typography>
    </Box>
  );
};

export default TermsAndUsePrivacyPolicy;
