import { Box, Paper, styled } from "@mui/material";

export const StyledOptionsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(2),
  margin: `${theme.spacing(6)} 0`,
}));

export const StyledOption = styled(Box)(({ theme }) => ({
  width: "72px",
  height: "72px",
  flexShrink: 0,
  borderRadius: "4px",
  border: "1px solid #E2E2E2",
  background: "#FFF",
  "& .title": { fontSize: "12px", fontWeight: "bold" },
  "& .description": {
    fontSize: "5px",
  },
}));

export const StyledPaper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "white",
  padding: theme.spacing(4),
  "& .MuiMobileStepper-root": {
    backgroundColor: "none",
  },
}));
