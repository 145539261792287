import React, {useEffect, useContext, useState} from "react";
import { Box, Button, Grid, TextField } from "@mui/material";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomTextField from "components/FormFields/CustomTextField";
import { useLocation, useNavigate } from "react-router-dom";
import { SignUpPathEnum } from "constants/signUpPath";
import GoogleAutocomplateBusiness from "components/FormFields/GoogleAutocomplateBusiness";
import { FormContext } from "context/formContext";
import {MainBox, StyledFooterBox, StyledNextButton, StyledSubTitle, StyledTitle, StyledTitleAI} from "./styles";
import { useGenerateCountQuery } from "api";
import TermsAndUsePrivacyPolicy from "./TermsPrivacy";
import sendToGTM from "./sendToGTM";
import CustomFormRadio from "components/FormFields/CustomRadio";

const initialDataModel = {
  firstName: "",
  lastName: "",
  // lastName: "",
  restaurantName: "",
  orOnOperate: ""
};

const formSchema = yup.object().shape({
  firstName: yup.string().required("This field is required"),
  lastName: yup.string().required("This field is required"),
  // lastName: yup.string().required("This field is required"),
  restaurantName: yup.string().required("This field is required"),
  orOnOperate: yup.string().required("This field is required"),
});

const NameInfo = () => {
  const { updateFormData } = useContext(FormContext);
  const { data } = useGenerateCountQuery();
  const navigate = useNavigate();
  const [isValidRadio, setIsValidRadio] = useState(false);
  const location = useLocation();

  const formMethods = useForm({
    mode: "all",
    defaultValues: initialDataModel,
    resolver: yupResolver(formSchema),
  });
  const {
    formState: { isSubmitted },
    reset,
    getValues,
    control,
  } = formMethods;
  const orOnOperateWatcher = useWatch({ control, name: 'orOnOperate' });

  const currentModel = getValues();

  const handleSubmit = async (data) => {
    const event = "oco.multiStepFormStepChange";
    const value = 1;
    const label = "Step 1 to Step 2 (Name Info)";
    const gtmData = { value, label, data, orOnOperate: Boolean(data?.orOnOperate === 'Yes') };
    sendToGTM(event, gtmData);
    if (data?.restaurantName) {
      localStorage.setItem('restaurantName', data?.restaurantName || '');
    }
    updateFormData(data);
    navigate(SignUpPathEnum.BUSINESS_INFO);
  };

  useEffect(() => {
    const model = getValues();
    setIsValidRadio(Boolean(model?.orOnOperate !== ""));
  },[orOnOperateWatcher])

  useEffect(() => {
    // const dataFromStorage = localStorage.getItem("formData");
    // const parsedData = JSON.parse(dataFromStorage);
    // if (parsedData) {
    //   reset(parsedData);
    // }
    localStorage.removeItem("formData");
    localStorage.removeItem("restaurantName");
    reset(initialDataModel);
  }, [location]);

  const onChangeRestaurantNameInput = (value) => {
    formMethods.setValue("restaurantName", value || "");
  };

  const getBusinessAddressFields = (data) => {
    reset({
      ...getValues(),
      restaurantName: data.name,
      addressInfo: data.addressInfo,
    });
    // setShowData(true);
  };

  const hanldeInvalidSubmit = (errr) => {
    console.log("errr", errr);
  };

  const formatNumber = (number, locale = "en-US") => {
    return new Intl.NumberFormat(locale).format(number);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12} sm={12}>
        <MainBox>
          <StyledTitle>Let's Get Started</StyledTitle>
          <StyledSubTitle>
            (US Restaurants Only)
          </StyledSubTitle>
          <StyledTitleAI>
            {data?.generatedCount
              ? `${formatNumber(
                data?.generatedCount || 0
              )} AI Generated Websites`
              : ""}
          </StyledTitleAI>
        </MainBox>
        <FormProvider {...formMethods}>
          <form
            onSubmit={formMethods.handleSubmit(
              handleSubmit,
              hanldeInvalidSubmit
            )}
            onKeyDown={handleKeyDown}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  placeholder={"Enter Your First Name"}
                  label="First Name *"
                  name="firstName"
                />
              </Grid>
              <Grid item xs={12} sm={6} >
                <CustomTextField
                  placeholder={"Enter Your Last Name"}
                  label="Last Name *"
                  name="lastName"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <GoogleAutocomplateBusiness
                  getAddressFields={getBusinessAddressFields}
                  onChangeInput={onChangeRestaurantNameInput}
                  inputValue={currentModel.restaurantName || ""}
                  initialValue={currentModel.restaurantName || ""}
                  name="restaurantName"
                  label="Restaurant Name *"
                  required={true}
                  formSubmitted={isSubmitted}
                  placeholder="Enter Your Restaurant Name"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box sx={{ textAlign: 'center' }}>
                  <CustomFormRadio
                    name={'orOnOperate'}
                    label={'Do You Own Or Operate The Restaurant?'}
                    options={[{value: 'Yes', label: 'Yes'}, {value: 'No', label: 'No'}]}
                    row={true}
                  />
                </Box>
              </Grid>
            </Grid>
            <StyledFooterBox sx={{ boxShadow: 4 }}>
              <Grid container m-0 p-0>
                <Grid item lg={6} md={12} xs={12} sm={12} m-0 p-0></Grid>
                <Grid item lg={6} md={12} xs={12} sm={12} m-0 p-0 sx={{ mt: 2 }}>
                  <StyledNextButton
                    disabled={!currentModel?.addressInfo?.address || !isValidRadio}
                    variant="contained"
                    type="submit"
                  >
                    Next
                  </StyledNextButton>
                  <TermsAndUsePrivacyPolicy />
                </Grid>
              </Grid>
            </StyledFooterBox>
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default NameInfo;