import Logo from 'assets/img/logoDesktop.svg';
import {useNavigate} from "react-router-dom";
import {HeaderLogoWrapper, MainContentWrapperHeader} from "pages/Header/styles";

export const Header = () => {
  const navigate = useNavigate();
  return (
   <MainContentWrapperHeader className={'backgroundLogo'} onClick={() => navigate("/")}>
     <HeaderLogoWrapper>
       <img src={Logo} alt="logo" />
     </HeaderLogoWrapper>
   </MainContentWrapperHeader>
  )
}