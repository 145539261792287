import React, { useState, useEffect, useContext } from "react";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomTextField from "components/FormFields/CustomTextField";
import { useLocation, useNavigate } from "react-router-dom";
import { SignUpPathEnum } from "constants/signUpPath";
import mailImg from "assets/img/mail.png";
import {
  StyledEmail,
  StyledFooterBox,
  StyledTitle,
  StyledBackButton,
  StyledNextButton, StyledVerifyTitle, StyledVerifyDescription, StyledEmailBox
} from "./styles";
import { FormContext } from "context/formContext";
import { useEmailVerifyMutation } from "api";
import TermsAndUsePrivacyPolicy from "./TermsPrivacy";
import sendToGTM from "./sendToGTM";

const initialDataModel = {
  email: "",
};

const formSchema = yup.object().shape({
  email: yup
    .string()
    .email("This field is invalid")
    .required("This field is required"),
});

const EmailInfo = () => {
  const { formData, updateFormData } = useContext(FormContext);
  const [verifyEmail] = useEmailVerifyMutation();
  const navigate = useNavigate();
  const location = useLocation();

  const formMethods = useForm({
    mode: "all",
    defaultValues: initialDataModel,
    resolver: yupResolver(formSchema),
  });
  const {
    formState: { isSubmitted },
    reset,
    getValues,
  } = formMethods;
  const handleSubmit = async (data) => {
    try {
      const restaurantNameInStorage = localStorage.getItem('restaurantName');
      const dataModel = {
        lastName: formData.lastName,
        firstName: formData.firstName,
        restaurantName: restaurantNameInStorage || formData.restaurantName,
        email: data.email,
        addressInfo: formData.addressInfo,
      };
      await verifyEmail(dataModel).unwrap();
      const event = "oco.multiStepFormStepChange";
      const value = 3;
      const label = "Step 3 to Step 4 (Email Info)";
      const gtmData = { value, label, data: data };
      sendToGTM(event, gtmData);
      updateFormData(data);
      navigate(SignUpPathEnum.EMAIL_VERIFY);
    } catch (error) {
      console.error(error);
    }
  };

  const goBack = () => {
    const event = "oco.multiStepFormStepChange";
    const value = 103;
    const label = "Step 3 to Step 2 (Email Info)";
    const gtmData = { value, label };
    sendToGTM(event, gtmData);
    navigate(-1);
  };

  useEffect(() => {
    const dataFromStorage = localStorage.getItem("formData");
    const parsedData = JSON.parse(dataFromStorage);
    if (parsedData) {
      const dataModel = {
        email: parsedData.email,
      };
      formMethods.reset(dataModel, { keepDirty: true, keepTouched: true, keepDirtyValues: true });
    } else {
      reset({ email: "" });
    }
  }, [location]);
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={12} lg={12} sm={12}>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                mt: 8,
                // justifyContent: "center",
                // alignItems: "center",
                height: "600px",
              }}
            >
              <StyledEmailBox>
                <Box sx={{ width: "112px", height: "115px" }}>
                  <StyledEmail src={mailImg} alt={"Send Email"} />
                </Box>
                <Box sx={{ maxWidth: "500px" }}>
                  <StyledVerifyTitle>
                    Verify Your Email
                  </StyledVerifyTitle>
                  <StyledVerifyDescription>
                    You’re Almost Ready To Generate Your AI Ordering Website!
                    Please Enter your Email Below.
                  </StyledVerifyDescription>
                </Box>
              </StyledEmailBox>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100px",
                  mt: 2
                }}
              >
                <CustomTextField
                  label="Provide Email Address"
                  name="email"
                  sx={{ minWidth: "300px" }}
                />
              </Box>
            </Box>
            <StyledFooterBox sx={{ boxShadow: 4 }}>
              <Grid container m-0 p-0>
                <Grid item lg={6} md={12} xs={12} sm={12} m-0 p-0></Grid>
                <Grid item lg={6} md={12} xs={12} sm={12} m-0 p-0 sx={{ mt: 2 }}>
                  <StyledBackButton variant="outlined" onClick={goBack}>
                    Back
                  </StyledBackButton>
                  <StyledNextButton
                      variant="contained"
                      type="submit"
                      disabled={!formMethods.formState.isValid}
                  >
                    Next
                  </StyledNextButton>
                  <TermsAndUsePrivacyPolicy />
                </Grid>
              </Grid>
            </StyledFooterBox>
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default EmailInfo;
