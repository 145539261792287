import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Preloader from "./Preloader/Preloader";
import ImageIcon from "@mui/icons-material/Image";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useGetDoordashStoresListMutation,
  // useGetMealMeMenuDownloadMutation,
  useLazyGetMealMeStoreQuery,
} from "api";
import {
  CustomAnimatedText, StyledBackButton,
  StyledFooterBox,
  StyledViewWebsiteButton,
} from "./styles";
import { useDispatch } from "react-redux";
import { setError } from "features/error";
import TypingEffect from "./TypingEffect";
import { useLocation, useNavigate } from "react-router-dom";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useMatchMobile } from "hooks/useMatchMobile";
import LanguageIcon from "@mui/icons-material/Language";
import sendToGTM from "./sendToGTM";
import { FormContext } from "context/formContext";
import TermsAndUsePrivacyPolicy from "./TermsPrivacy";
import CustomSlider from "./SliderBar";

const timerForTimeout = 60;

function ProgressBar({
  texts = [
    "Gathering Information",
    "Downloading Menus",
    // "Deploying Information and Choosing Design",
    "Attaching Menu",
    "Decorating The Preview",
    "Making Dinal Touches…",
  ],
  step = 360,
  restaurantLink,
}) {
  const dispatch = useDispatch();
  const { isMatchMobile } = useMatchMobile();
  const [timer, setTimer] = useState(0);
  const [progress, setProgress] = useState(0);
  const [displayedText, setDisplayedText] = useState("...");

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        // Update text based on progress within the setState callback
        if (prev >= 0 && prev < 10 && displayedText !== texts[0]) {
          setDisplayedText(texts[0]);
        } else if (prev >= 10 && prev < 20 && displayedText !== texts[1]) {
          setDisplayedText(texts[1]);
        } else if (prev >= 20 && prev < 30 && displayedText !== texts[2]) {
          setDisplayedText(texts[2]);
        } else if (prev >= 30 && prev < 40 && displayedText !== texts[3]) {
          setDisplayedText(texts[3]);
        }
        return prev + 1;
      });
    }, step);

    if (restaurantLink) {
      setProgress(100);
    }

    if (progress >= 100) {
      setProgress(100);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [progress, texts, displayedText, step, restaurantLink]);

  useEffect(() => {
    if (timer >= timerForTimeout) {
      dispatch(
        setError({
          isTimeout: true,
          isServer: false,
          errorMessage: true,
        })
      );
    }
  }, [timer]);

  useEffect(() => {
    // Start a timer when the component mounts
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);

    if (restaurantLink) {
      const event = "oco.websiteGenerationSuccess";
      const value = 777;
      const label = "Website Generation Finished (Success)";
      const gtmData = { value, label };
      sendToGTM(event, gtmData);
      clearInterval(intervalId);
    }
    if (timer >= timerForTimeout) {
      const event = "oco.websiteGenerationTimeout";
      const value = 765;
      const label = "Website Generator Timeout (Failed)";
      const gtmData = { value, label };
      sendToGTM(event, gtmData);
      clearInterval(intervalId);
    }

    // Clean up the timer when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [restaurantLink, timer]);

  const formattedTime = new Date(timer * 1000).toISOString().substr(14, 5);

  return (
    <Box>
      <CustomAnimatedText>{formattedTime}</CustomAnimatedText>
      <Box sx={{ background: "#95FAFA" }}>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{ height: "20px",
            backgroundColor: '#95FAFA',
            color: '#2FDADA',
            '.MuiLinearProgress-bar': {
              backgroundColor: '#2FDADA',
            }
          }}
        />
      </Box>
      {!restaurantLink && (
        <Typography sx={{ fontSize: "20px", mt: 1, fontFamily: 'Poppins' }}>
          {displayedText}
        </Typography>
      )}
    </Box>
  );
}

const Success = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMatchMobile } = useMatchMobile();
  const [getMealMeStore] = useLazyGetMealMeStoreQuery();

  const id = location.state?.id;
  const [regenerated, setRegenerated] = useState(false);
  const [loading, setLoading] = useState(false);

  const [restaurantLink, setRestaurantLink] = useState([]);
  const intervalIdRef = useRef(null);

  useEffect(() => {
    window.scroll(0, 0);
  },[])

  const makeAPICall = async () => {
    try {
      const response = await getMealMeStore(id).unwrap();
      if (response?.error) {
        clearInterval(intervalIdRef.current);
        dispatch(
          setError({
            isTimeout: true,
            errorMessage: true,
          })
        );
      }
      if (response?.storeInfo) {
        setRestaurantLink(response.storeInfo);
        localStorage.removeItem("formData");
        // Clear the interval when the response is successful
        clearInterval(intervalIdRef.current);
      }
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };

  useEffect(() => {
    if (id) {
      // Set up interval to make API call every 5 seconds
      const event = "oco.websiteGenerationStarted";
      const value = 700;
      const label = "Website Generator Started (Final Step)";
      const gtmData = { value, label };
      sendToGTM(event, gtmData);
      intervalIdRef.current = setInterval(makeAPICall, 5000);
      localStorage.removeItem("formData");
    }

    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, [id]);

  const goToLink = (data) => {
    const url = `${data.restaurantPage}?menuId=${data.menuId}`; // Replace with the URL you want to open
    const event = "oco.generatedWebsiteDemoMenuClicked";
    const value = 800;
    const label = "Generated Website Demo Menu Clicked (Success)";
    const gtmData = { value, label };
    sendToGTM(event, gtmData);
    // let link = {
    //   url: url,
    //   isRegenerate: Boolean(restaurantLink.length > 1),
    // }
    // localStorage.setItem('websiteCredentials', JSON.stringify(link));
    // navigate('/website');
    window.open(url, "_blank");
  };

  const handleStartOver = () => {
    localStorage.removeItem("formData");
    const event = "oco.multiStepFormStepChange";
    const value = 9;
    const label = "Final Step to Step 1 (Final Step)";
    const gtmData = { value, label };
    sendToGTM(event, gtmData);
    navigate("/");
  };

  const linkData =
    regenerated && restaurantLink.length
      ? restaurantLink
      : restaurantLink.length
      ? [restaurantLink[0]]
      : [];

  const handleRegenerate = () => {
    setLoading(true);
    const event = "oco.websiteGenerationRegenerateClicked";
    const value = 701;
    const label = "Website Generator Clicked Regenerate (Final Step)";
    const gtmData = { value, label };
    sendToGTM(event, gtmData);
    setTimeout(() => {
      setLoading(false);
      setRegenerated(true);
    }, 2000);
  };

  return (
    // <Grid container justifyContent="center">
    //   <Grid item xs={12} md={12} lg={12} sm={12}>
    <Box>
      {!linkData.length && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            // justifyContent: "center",
            margin: "64px auto",
            position: "relative",
            p: 2,
            boxSizing: "border-box",
            width: "100%",
          }}
        >
          <Typography variant="h5" sx={{ my: 2, fontFamily: 'Poppins', fontSize: '20px'  }}>
            {process.env.REACT_APP_SUCCESS_TEXT}
          </Typography>
          <Box sx={{ width: "100%", my: 1 }}>
            <ProgressBar restaurantLink={restaurantLink.length} />
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
          position: "relative",
          p: 2,
          boxSizing: "border-box",
          width: "100%",
        }}
      >

        {linkData.length ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              // alignItems: "center",
              flexDirection: "column",
              mt: 4,
              p: 2,
              gap: 2,
            }}
          >
            <Typography variant="h5" sx={{ my: 2, mb: 3, fontFamily: 'Poppins' }}>
              Your Website is Ready !
            </Typography>
            <Box sx={{ width: "100%", my: 1 }}>
              <CustomSlider />
              {/*<ProgressBar restaurantLink={restaurantLink.length} />*/}
            </Box>
            {linkData.map((item ,index) => {
              return (
                <Box>
                  <StyledViewWebsiteButton
                    key={item._id}
                    onClick={() => goToLink(item)}
                    variant="contained"
                  >
                    <Typography sx={{ fontWeight: 700, fontFamily: 'Poppins' }}>{`VIEW YOUR MENU VERSION ${index + 1}`}</Typography>
                  </StyledViewWebsiteButton>
                </Box>
              )
            })}
            {restaurantLink.length > 1 && (
              <StyledViewWebsiteButton
                sx={{ color: "black", width: "100%" }}
                variant="outlined"
                onClick={handleRegenerate}
                disabled={linkData.length === 2}
              >
                Regenerate
              </StyledViewWebsiteButton>
            )}
          </Box>
        ) : (
          <></>
        )}
          <StyledFooterBox sx={{ boxShadow: 4 }}>
              <Grid container m-0 p-0>
                <Grid item lg={6} md={12} xs={12} sm={12} m-0 p-0></Grid>
                {linkData.length ? (
                  <Grid item lg={6} md={12} xs={12} sm={12} m-0 p-0 sx={{ mt: 2 }}>
                    <Button
                      sx={{
                        height: "53px !important",
                        fontSize: "15px",
                        borderRadius: '6px!important',
                        textTransform: "none",
                        fontWeight: 700,
                        boxShadow: "none",
                        fontFamily: 'Poppins',
                        color: 'white',
                        background: 'linear-gradient(to right, rgba(255, 107, 0, 1) 0%, rgba(173, 42, 0, 1) 100%)',
                        '&:disabled': {
                          background: 'rgba(224, 224, 224, 1)',
                        }
                      }}
                      variant="contained"
                      onClick={handleStartOver}
                      startIcon={<RestartAltIcon />}
                    >
                      Start Over
                    </Button>
                    {/*<List disablePadding>*/}
                    {/*  {linkData.map((item, index) => {*/}
                    {/*    return (*/}
                    {/*      <StyledViewWebsiteButton*/}
                    {/*        key={item._id}*/}
                    {/*        onClick={() => goToLink(item)}*/}
                    {/*        variant="contained"*/}
                    {/*        sx={{ width: "100%", mb: 2 }}*/}
                    {/*      >*/}
                    {/*        <ListItem sx={{ px: 0, fontWeight: 700 }}>*/}
                    {/*          <ListItemText*/}
                    {/*            sx={{ textAlign: "center", fontWeight: 700 }}*/}
                    {/*          >*/}
                    {/*            <Typography sx={{ fontWeight: 700 }}>{`VIEW YOUR MENU VERSION ${index + 1}`}</Typography>*/}
                    {/*          </ListItemText>*/}
                    {/*        </ListItem>*/}
                    {/*      </StyledViewWebsiteButton>*/}
                    {/*    );*/}
                    {/*  })}*/}
                    {/*</List>*/}
                    <TermsAndUsePrivacyPolicy />
                  </Grid>
                ) : null}
              </Grid>
              {/*{restaurantLink.length ? (*/}
              {/*  <Grid container m-0 p-0>*/}
              {/*    <Grid item lg={6} md={12} xs={12} sm={12} m-0 p-0></Grid>*/}
              {/*    <Grid item lg={6} md={12} xs={12} sm={12} m-0 p-0>*/}
              {/*      <Button*/}
              {/*        variant="contained"*/}
              {/*        onClick={handleStartOver}*/}
              {/*        startIcon={<RestartAltIcon />}*/}
              {/*      >*/}
              {/*        Start Over*/}
              {/*      </Button>*/}
              {/*    </Grid>*/}
              {/*  </Grid>*/}
              {/*) : null}*/}
            </StyledFooterBox>
      </Box>
    </Box>
    //   </Grid>
    // </Grid>
  );
};

export default Success;
